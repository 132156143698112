import React, { useEffect, useState } from "react";
import "./style.scss";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { logout } from "service/helpers/utilities";

import profileActive from "assets/icons/profileActive.svg";
import profileInactive from "assets/icons/profileInactive.svg";

import consultationInactive from "assets/icons/consultationInactive.svg";
import consultationActive from "assets/icons/consultationActive.svg";

import myOrderActive from "assets/icons/myOrderActive.svg";
import myOrderInactive from "assets/icons/myOrderInactive.svg";

import settingsInactive from "assets/icons/settingsInactive.svg";
import settingsActive from "assets/icons/settingsActive.svg";

import faqInactive from "assets/icons/faqInactive.svg";
import faqActive from "assets/icons/faqActive.svg";
import { MdDelete } from "react-icons/md";


import blogsActive from "assets/icons/blogsActive.svg";
import blogsInactive from "assets/icons/blogsInactive.svg";

import webinars from "assets/icons/webinars.svg";

import contactus from "assets/icons/contactus.svg";
import trash from "assets/icons/trashTwo.png";

import medicalhistory from "assets/icons/medicalhistory.svg";

import subcriptionInactive from "assets/icons/subcriptionInactive.svg";
import subcriptionActiveIcon from "assets/icons/subcriptionActiveIcon.svg";
import { useSelector } from "react-redux";
import { subscriptionWidget } from "helpers";

const SideBarComp = () => {
  const userProfile = useSelector((state) => state?.home?.userData);
  const SSO  = useSelector((state) => state?.home?.sso)

  let navLink = []

  if(SSO) {
    navLink = [
      {
        to: "/patient/consultations",
        label: "My Consultations",
        activeIcon: consultationActive,
        inactiveIcon: consultationInactive,
        isHide: false,
      },
      {
        to: "/patient/health-record",
        label: "Health Records",
        activeIcon: myOrderActive,
        inactiveIcon: myOrderInactive,
        isHide: false,
      },
      {
        to: "/auth/medicalHistory/basic",
        label: "Medical History",
        activeIcon: medicalhistory,
        inactiveIcon: medicalhistory,
        isHide: false,
      },   
      {
        to: "/patient/settings",
        label: "Settings",
        activeIcon: settingsActive,
        inactiveIcon: settingsInactive,
        isHide: false,
      },
  
      {
        to: "/patient/faq",
        label: "FAQ",
        activeIcon: faqActive,
        inactiveIcon: faqInactive,
        isHide: false,
      },
    ];
  } else {
    navLink = [
      {
        to: "/patient/my-profile",
        label: "My Profile",
        activeIcon: profileActive,
        inactiveIcon: profileInactive,
        isHide: false,
      },
      {
        to: "/patient/consultations",
        label: "My Consultations",
        activeIcon: consultationActive,
        inactiveIcon: consultationInactive,
        isHide: false,
      },
      {
        to: "/patient/health-record",
        label: "Health Records",
        activeIcon: myOrderActive,
        inactiveIcon: myOrderInactive,
        isHide: false,
      },
      {
        to: "/patient/myOrders",
        label: "My Orders",
        activeIcon: myOrderActive,
        inactiveIcon: myOrderInactive,
        isHide:
          userProfile?.isHideMedicine && userProfile?.isHideDiagnostic
            ? true
            : false,
      },
      {
        to: "/auth/medicalHistory/basic",
        label: "Medical History",
        activeIcon: medicalhistory,
        inactiveIcon: medicalhistory,
        isHide: false,
      },
      {
        to: "/patient/family-tree",
        label: "Family Tree",
        activeAt: ["/patient/family-tree", '/patient/members'],
        activeIcon: profileActive,
        inactiveIcon: profileInactive,
        isHide: false,
      },
  
      {
        to: "/blogs",
        label: "Blogs",
        activeIcon: blogsActive,
        inactiveIcon: blogsInactive,
        isHide: false,
      },
      {
        to: "/webinars",
        label: "Webinars",
        activeIcon: webinars,
        inactiveIcon: webinars,
        isHide: false,
      },
      {
        to: "/contact-us",
        label: "Contact Us",
        activeIcon: contactus,
        inactiveIcon: contactus,
        isHide: false,
      },
  
      {
        to: "/patient/settings",
        label: "Settings",
        activeIcon: settingsActive,
        inactiveIcon: settingsInactive,
        isHide: false,
      },
  
      {
        to: "/patient/faq",
        label: "FAQ",
        activeIcon: faqActive,
        inactiveIcon: faqInactive,
        isHide: false,
      },
      {
        to: "/patient/delete-account",
        label: "Delete Account",
        activeIcon: trash,
        inactiveIcon: trash,
        isHide: false,
      },
    ];
  }


  const navLinkWithSubscription = [
    {
      to: "/patient/my-profile",
      label: "My Profile",

      activeIcon: profileActive,
      inactiveIcon: profileInactive,
      isHide: false,
    },
    {
      to: "/patient/consultations",
      label: "My Consultations",
      activeIcon: consultationActive,
      inactiveIcon: consultationInactive,
      isHide: false,
    },
    {
      to: "/patient/health-record",
      label: "Health Records",
      activeIcon: myOrderActive,
      inactiveIcon: myOrderInactive,
      isHide: false,
    },
    {
      to: "/patient/myOrders",
      label: "My Orders",
      activeIcon: myOrderActive,
      inactiveIcon: myOrderInactive,
      isHide:
        userProfile?.isHideMedicine && userProfile?.isHideDiagnostic
          ? true
          : false,
    },
    {
      to: "/auth/medicalHistory/basic",
      label: "Medical History",
      activeIcon: medicalhistory,
      inactiveIcon: medicalhistory,
      isHide: false,
    },
    {
      to: "/patient/family-tree",
      label: "Family Tree",
      activeIcon: profileActive,
      inactiveIcon: profileInactive,
      isHide: false,
    },

    {
      to: "/blogs",
      label: "Blogs",
      activeIcon: blogsActive,
      inactiveIcon: blogsInactive,
      isHide: false,
    },
    {
      to: "/webinars",
      label: "Webinars",
      activeIcon: webinars,
      inactiveIcon: webinars,
      isHide: false,
    },
    {
      to: "/contact-us",
      label: "Contact Us",
      activeIcon: contactus,
      inactiveIcon: contactus,
      isHide: false,
    },
    {
      to: "/patient/subcription-list",
      label: "Subscriptions",
      activeIcon: subcriptionActiveIcon,
      inactiveIcon: subcriptionInactive,
      isHide: false,
    },
    {
      to: "/patient/settings",
      label: "Settings",
      activeIcon: settingsActive,
      inactiveIcon: settingsInactive,
      isHide: false,
    },

    {
      to: "/patient/faq",
      label: "FAQ",
      activeIcon: faqActive,
      inactiveIcon: faqInactive,
      isHide: false,
    },
    {
      to: "/patient/delete-account",
      label: "Delete Account",
      activeIcon: trash,
      inactiveIcon: trash,
      isHide: false,
    },
  ];

  const nav2 = [
    {
      label: "BACK TO HOME",
      to: SSO ? "/patient/consultations" : "/patient/my-profile",
    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [navigation, setNavigation] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (userProfile?.isSubscribedUser) {
      setNavigation(navLinkWithSubscription);
    } else {
      setNavigation(navLink);
    }
  }, [userProfile]);


  const routeData = (data) => {

    let currPath = window.location.pathname
    let bool = data?.includes(currPath)

    return bool
  }


  return (
    <div className="sideBar">
      <List
        style={{
          textDecoration: "none",
        }}
      >
        {navigation.map(
          (
            { to, label, activeIcon, inactiveIcon, nestedChild, isHide, activeAt },
            index
          ) => (
            <>
              <div
                key={index}
                style={{
                  borderRight:
                    location.pathname === to || routeData(activeAt) ? "4px solid #0E69C2" : null,
                }}
              >
                <NavLink
                  key={index}
                  to={to}
                  onClick={
                    to !== "/something" ? () => setActiveIndex(index) : ""
                  }
                  style={{ textDecoration: "none" }}
                >
                  <ListItem
                    button
                    className={
                      location.pathname === to || routeData(activeAt)
                        ? `active-div py-3 ${isHide && "d-none"}`
                        : `inActive-div py-3 ${isHide && "d-none"}`
                    }
                  >
                    <div>
                      <ListItemText>
                        <span className="mr-4 ml-4">
                          <img
                            src={
                              location.pathname === to || routeData(activeAt)
                                ? activeIcon
                                : inactiveIcon
                            }
                          />
                        </span>

                        <span
                          className={
                            location.pathname === to || routeData(activeAt)
                              ? "activeBar"
                              : "inActiveBar"
                          }
                        >
                          {label}
                        </span>
                      </ListItemText>
                    </div>
                  </ListItem>
                </NavLink>
              </div>
            </>
          )
        )}
      </List>
      <div>
        <List
          style={{
            textDecoration: "none",
          }}
        >
          {nav2.map(
            (
              { to, label, iconName, iconTransparent, nestedChild, isHide },
              index
            ) => (
              <>
                <div className="mt-5">
                  <NavLink
                    key={index}
                    to={to}
                    onClick={
                      to !== "/something" ? () => setActiveIndex(index) : ""
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <ListItem
                      button
                      style={{ borderTop: "0.5px solid #DDDDDD" }}
                      className="py-3"
                    >
                      <div>
                        <ListItemText>
                          <span className="mr-4 ml-4">
                            <img src={iconName} />
                          </span>

                          <span style={{ color: "#4284F3" }}>{label}</span>
                        </ListItemText>
                      </div>
                    </ListItem>
                    <hr className="m-0"></hr>
                  </NavLink>
                </div>
              </>
            )
          )}
        </List>
      </div>
    </div>
  );
};

export default SideBarComp;
