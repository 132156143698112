import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Routes from "./routes/index";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store, persistor } from "redux/store/store";
import "./assets/scss/index.scss";
import "./assets/scss/_abstracts.scss";
import "antd/dist/antd.css";
import { PersistGate } from "redux-persist/integration/react";
import "react-notifications/lib/notifications.css";
import { ProvideCart } from "hooks/useCart";
import "swiper/swiper-bundle.css";
import { DiagnosisCart } from "hooks/useDiagnosisCart";
import MobileRedirect from "routes/MobileRedirect";
import { Toast } from "service/helpers/toast";
import 'bootstrap/dist/css/bootstrap.min.css';
require("dotenv").config();

document.addEventListener('copy', function (e) {
  e.preventDefault(); // Prevent the default copy action
  Toast({ type: "error", message: 'Copying is disabled on this site for security reasons.' });
});

document.addEventListener('contextmenu', function(event) {
  event.preventDefault();
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <MobileRedirect>
        <ProvideCart>
          <DiagnosisCart>
            <Routes />
          </DiagnosisCart>
        </ProvideCart>
      </MobileRedirect>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
