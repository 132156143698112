import React, { useState } from "react";
import {
  NavDropdown,
  Nav,
  Navbar,
  Container,
  Row,
  ListGroup,
} from "react-bootstrap";
import "./style.scss";
import logo from "assets/images/meradoc.png";
import { GrFacebookOption } from "react-icons/gr";
import { AiFillYoutube, AiFillInstagram } from "react-icons/ai";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { history } from "helpers";
import MobileStickyTab from "../MobileStickyTab";
import { getHeaderUserDetail } from "service/MyProfile";
import { Toast } from "service/helpers/toast";
import { getToken } from "service/helpers/utilities";
import { useSelector } from "react-redux";
import applestore from "assets/images/appstore.png";
import playstore from "assets/images/playstore.png";
import { BiPhoneCall } from "react-icons/bi";

const FooterComp = () => {
  // const handleRedirectLogin = (path) => {
  //   if (getToken() !== null || "") {
  //     history.push(path);
  //   }
  // };
  const userProfile = useSelector((state) => state?.home?.userData);

  return (
    <>
      <div className="footer-item light-bg">
        <div className="icon-bar">
          <a
            href="tel:1800 3090 101"
            // target="_blank"
            className="stickyText"
            aria-label="call icon"
          >
            <span className="p-1">
              {" "}
              <BiPhoneCall />{" "}
            </span>{" "}
            {/* Need Assistance Call Us */}
          </a>
        </div>
        <div className="main-container ">
          <div className="row m-0 mb-5 ">
            <div className="col-lg-5 col-12 footer-logo">
              <img
                className="meradoc-logo cursor-pointer"
                onClick={() => history.push("/")}
                src={logo}
                alt="logo img"
              ></img>
              <p>
                24*7 Online doctor consultation - Feel better with your MeraDoc
                General Physicians
              </p>
              <p className="m-0">
                Call Us :{" "}
                <a
                  href="tel:1800 3090 101"
                  // target="_blank"
                  className="hyperLink"
                >
                  1800 3090 101
                </a>
              </p>
              <p>
                Mail Us :{" "}
                <a
                  href="mailto:https://contact@meradoc.com"
                  // href="https://www.google.com/gmail/"
                  target="_blank"
                  className="hyperLink"
                  aria-label="mail link"
                >
                  contact@meradoc.com
                </a>
              </p>
              <p className="m-0">
                {/* Address: B-84, Defence Colony, New&nbsp;Delhi&nbsp;-&nbsp;110024 */}
                Address:{" "}
                <a
                  className="hyperLink"
                  target="_blank"
                  href="https://maps.google.com/?q=B-84,%20Defence%20Colony,%20New%20Delhi,%20Delhi%20110024"
                  aria-label="Address link"
                >
                  B-84, Defence Colony, New&nbsp;Delhi&nbsp;-&nbsp;110024
                </a>
              </p>
              <div className="media-img">
                <a
                  href="https://www.facebook.com/MERADOC-100503372249288"
                  target="_blank"
                  aria-label="facebooklink"
                >
                  <GrFacebookOption className="cursor-pointer" />
                </a>
                <a href="https://twitter.com/meradocofficial" target="_blank" aria-label="twitter link">
                  <FaTwitter className="cursor-pointer" />
                </a>
                <a
                  href="https://www.linkedin.com/company/MERADOC-healthtech-private-limited"
                  target="_blank"
                  aria-label="linkedin link"
                >
                  <FaLinkedinIn className="cursor-pointer" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUcLWacPu36bv5xZPuf-Alw"
                  target="_blank"
                  aria-label="You tube link"
                >
                  <AiFillYoutube className="cursor-pointer" />
                </a>
                <a
                  href="https://www.instagram.com/meradocofficial/?hl=en"
                  target="_blank"
                  aria-label="instagram link"
                >
                  <AiFillInstagram className="cursor-pointer" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-6">
              <ListGroup as="ul" className="footer-list">
                <ListGroup.Item as="li" className="activeFoot">
                  Know MeraDoc
                </ListGroup.Item>
                <ListGroup.Item
                  className="linksToDirect"
                  as="li"
                  onClick={() => history.push("/about-us")}
                >
                  About Us
                </ListGroup.Item>
                <ListGroup.Item
                  className="linksToDirect"
                  as="li"
                  onClick={() => history.push("/blogs")}
                >
                  Our Blogs
                </ListGroup.Item>
                <ListGroup.Item
                  className="linksToDirect"
                  as="li"
                  onClick={() => history.push("/webinars")}
                >
                  Our Webinars
                </ListGroup.Item>

                <ListGroup.Item
                  className="linksToDirect"
                  as="li"
                  onClick={() =>
                    history.push("/contact-us")
                  }
                >
                  Contact Us
                </ListGroup.Item>
                <ListGroup.Item
                  className="linksToDirect"
                  as="li"
                  // onClick={() => history.push("/bookSpecialist")}
                >
                  For Booking Assistance Call -{" "}
                  <a
                    href="tel:1800 3090 101"
                    // target="_blank"
                    className="hyperLink"
                    aria-label="Call link"
                  >
                    1800 3090 101
                  </a>
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="col-lg-3 col-6">
              <ListGroup as="ul" className="footer-list">
                <ListGroup.Item as="li" className="activeFoot">
                  MeraDoc Services:
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() => history.push("/book/bookConsultationGP")}
                >
                  Family Physician
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() => history.push("/bookSpecialist")}
                >
                  Consult a Specialist
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() => history.push("/offers")}
                >
                  Offers
                </ListGroup.Item>
                {!userProfile?.isHideMedicine && (
                  <ListGroup.Item
                    as="li"
                    className="linksToDirect"
                    onClick={() => history.push("/search-medicine")}
                  >
                    Medicine
                  </ListGroup.Item>
                )}
                {!userProfile?.isHideDiagnostic && (
                  <ListGroup.Item
                    as="li"
                    className="linksToDirect"
                    onClick={() => history.push("/diagnostic-choice")}
                  >
                    Diagnostics
                  </ListGroup.Item>
                )}

                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() =>
                    history.push("/wellness")
                  }
                >
                  Wellness Offerings
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() =>
                    history.push(`/corporate`)
                  }
                >
                  Corporate
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() => history.push(`/career`)}
                >
                  Career
                </ListGroup.Item>
              </ListGroup>
            </div>
            <div className="col-lg-2 col-6">
              <ListGroup as="ul" className="footer-list">
                <ListGroup.Item as="li" className="activeFoot">
                  Our Policies:
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() =>
                    history.push("/auth/terms?TYPE=TERMS_AND_CONDITIONS")
                  }
                >
                  Terms of Use
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() =>
                    history.push("/auth/privacy-policy?TYPE=PRIVACY_POLICY")
                  }
                >
                  Privacy Policy
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="linksToDirect"
                  onClick={() =>
                    history.push("/auth/refund-policy?TYPE=REFUND_POLICY")
                  }
                >
                  Refund Policy
                </ListGroup.Item>
              </ListGroup>
              <div className="doc-content-desktop pt-4">
                <div className="text-center">
                  <p className="app-download">Download Our&nbsp;App</p>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="playstore-img">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.meradoc"
                      target="_blank"
                      aria-label="Google play store link"
                    >
                      <img src={playstore} className={`w-100`} alt="playstore image"></img>
                    </a>
                    <div className="mt-3">
                      <a
                        href="https://apps.apple.com/app/meradoc/id1609362664"
                        target="_blank"
                        aria-label="Apple store link"
                      >
                        <img className="w-100" src={applestore} alt="applestore"></img>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="doc-content-mobile mt-4 col-6">
              <div className="text-left">
                <p className="app-download">Download Our&nbsp;App</p>
              </div>
              <div className="d-flex flex-wrap justify-content-center align-items-center">
                <div className="playstore-img">
                  <a
                    href="https://apps.apple.com/app/meradoc/id1609362664"
                    target="_blank"
                    aria-label="Donload app"
                  >
                    <img className="w-100" src={applestore} alt="apple store img"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Navbar className="footer-content">
          <Container>
            <Navbar.Brand href="#home">Copyright © 2022 MeraDoc</Navbar.Brand>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
export default FooterComp;
