import React, { useEffect } from "react";

import Header from "component/common/Header";
import FooterComp from "../component/common/FooterComp";
import MobileStickyTab from "component/common/MobileStickyTab";
import { getToken } from "service/helpers/utilities";

export function CommonLayout(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.scroll({ behavior: "smooth" });
  });

  console.log(window.location.pathname,"window.location.pathname")
  
  return (
    <div>
      <div className="commonLayoutWrapper">
        <Header />
        <div className="mt-5 pt-0">{props.children}</div>
        <FooterComp />
        <div className="d-md-none d-block">
        {getToken() != null && <MobileStickyTab />}
        </div>
      </div>
    </div>
  );
}
