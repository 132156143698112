import { getHeaderUserDetail } from "service/MyProfile";

export const userDetail = (data) => {
  return {
    type: "USER_DETAIL",
    payload: data,
  };
};

export const ssoDetails = (data) => {
  return {
    type: "SSO_DETAIL",
    payload: data,
  };
};
export const tennantDetails = (data) => {
  return {
    type: "TENNANT_DETAIL",
    payload: data,
  };
};
// export const storeTxnIdAbha = (data) => {
//   return {
//     type: "ABHA_SERVICE",
//     payload: data,
//   };
// };
export const setSessionTokenAbha = (data) => {
  return {
    type: "ABHA_SESSION_TOKEN",
    payload: data,
  };
};
export const storeTxnIdAbha = (data) => {
  return {
    type: "ABHA_TXN_ID_OTP",
    payload: data,
  };
};
export const storeAbhaPatNumber = (data) => {
  return {
    type: "ABHA_PATIENT_NUMBER",
    payload: data,
  };
};
export const addStoreData = (data) => {
  return {
    type: "ADD_STORE_DATA",
    payload: data,
  };
};


export const patientId = (data) => {
  return {
    type: "PATIENT_ID",
    payload: data,
  };
};

export const diagnosticsLocation = (data) => {
  return {
    type: "DIAGNOSTIC_LOCATION",
    payload: data,
  };
};

export const registerUserDetail = (data) => {
  return {
    type: "REGISTER_USER",
    payload: data,
  };
};

export const mobileNumberLogin = (data) => {
  return {
    type: "MOBILE_NUMBER_LOGIN",
    payload: data,
  };
};

export const cancelPaymentAppointmentNumber = (data) => {
  return {
    type: "CANCEL_APPOINTMENT_NUMBER",
    payload: data,
  };
};

export const MultiFamilyMember = (data) => {
  return {
    type: "MULTI_FAMILY_MEMBER",
    payload: data,
  };
};

export const WearableSource = (data) => {
  return {
    type: "GET_USER_PROFILE_WEARABLE",
    payload: data,
  };
};

export const RescheduleDiagnosisAppointment = (data) => {
  return {
    type: "RESCHEDULE_DIAGNOSIS_APPOINTMENT",
    payload: data,
  };
};
export const setUserAddressId = (id) => {
  return {
    type: "UPDATE_ADDRESS_ID",
    payload: id,
  };
};
export const setMedicineCart = (cart) => {
  return {
    type: "UPDATE_MEDICINE_CART",
    payload: cart,
  };
};
// export const UpdateMedicineQuantity = (data) => {
//   return {
//     type: "UPDATE_MED_QTY",
//     payload: data,
//   };
// };

export const getUserProfile = () => async (dispatch, getState) => {
  try {
    const { data: { data } = {} } = await getHeaderUserDetail();
    await dispatch({
      type: "GET_USER_PROFILE_DATA",
      payload: data ?? {},
    });
  } catch (e) {
    await dispatch({
      type: "GET_USER_PROFILE_DATA",
      payload: {},
    });
  }
};
