const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
const monthsMapping = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sept: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'



}
const day = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', "22", '23', '24', '25', '26', '27', '28', '29', '30', '31']

const Relationship = [
    "FATHER",
    "MOTHER",
    "DAUGHTER",
    "SON",
    "BROTHER",
    "SISTER",
    "WIFE",
    "HUSBAND",
    "FRIEND"
]
const FAMILY_SLOT_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}


const Errors = {

    NOT_FOUND:'Data Not Found'

}

const ListOfRefferalCodeThatWantToBeHideAppBar = [
    "Pharmeasy_SSO",
]

module.exports = {
    months,
    day,
    monthsMapping,
    Relationship,
    FAMILY_SLOT_STATUS,
    Errors,
    ListOfRefferalCodeThatWantToBeHideAppBar
}